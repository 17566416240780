import React from 'react'
import { Link } from 'gatsby'

export default ({ itemType }) => (
    <Link to={itemType === 'Logo' ? '/logos' : itemType === 'Wedding Invite' ? '/stationary': '/'}  style={{textDecoration: 'none', display: 'block', padding: '1rem', fontSize: '1.25rem', cursor: 'pointer'}}>
    <span style={{display: 'inline-block', marginRight: '10px'}}>←</span>
    <span>{itemType === 'Logo' ? 'Back to Logos and Branding' : itemType === 'Wedding Invite' ? 'Back to Print Design': 'Back'}</span>
  </Link>
)


