import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import Back from '../components/back';

class AboutIndex extends React.Component {
  render() {
    const about = get(this, 'props.data.allContentfulPerson.edges')[0].node;

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title="About Dana Royal" />
          
          <Back/>
          <div style={{margin: '5rem'}}>
            <Img style={{width: '200px', borderRadius: '50%', textAlign: 'center', margin: 'auto'}}
              alt={about.name}
              fluid={about.heroImage.fluid}/>
          </div>
          <div style={{maxWidth: '800px', margin: 'auto', padding: '1rem', marginBottom: '5vh'}}>
            <div style={{whiteSpace: 'pre-line'}}>{about.shortBio.shortBio}</div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutIndex

export const pageQuery = graphql`
  query AboutIndexQuery {
    allContentfulPerson(
      filter: { contentful_id: { eq: "3jdSP9kZxYEPQsHlQn9ypU" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          heroImage: image {
            fluid(
              maxWidth: 1000
              maxHeight: 1000
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
